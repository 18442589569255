import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { LoadinSpiner } from "components/LoadingSpinner";
import { snackbar } from "components/awesome_snackbar/snackbar";
import ConfirmationDialog from "helper/comfirmationOfDelete";
import AddEventForm from "./addEventForm";
import EditableTable from "helper/editableTable";
import { getEvents } from "utils/requests/event/getEvents";
import { deleteEvent } from "utils/requests/event/createEvent";
import { updateEvents } from "utils/requests/event/createEvent";
import PropTypes from "prop-types";
// Validation function for required fields
const validateRequired = (value) => value && value.trim().length > 0;

const validateEvent = (data) => ({
  title: !validateRequired(data.title) ? "Event title is required" : "",
  description: !validateRequired(data.description) ? "Event description is required" : "",
  banner: !validateRequired(data.banner) ? "Event banner is required" : "",
  organizer: !validateRequired(data.organizer) ? "Event organizer is required" : "",
  time: !validateRequired(data.time) ? "Event time is required" : "",
  date: !validateRequired(data.date) ? "Event date is required" : "",
  location: !validateRequired(data.location) ? "Event location is required" : "",
});

const ImageCell = ({ renderedCellValue }) => {
  return <img width="100%" src={renderedCellValue} alt="Event Banner" />;
};

ImageCell.propTypes = {
  renderedCellValue: PropTypes.string.isRequired,
};

export default function EventsPage() {
  const [rows, setRows] = useState([]);
  const [addVisibility, setAddVisibility] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);

  const columnsConfig = [
    {
      accessorKey: "title",
      header: "Event Name",
      size: 200,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors.title,
        helperText: validationErrors.title,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, title: undefined })),
      },
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 200,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors.description,
        helperText: validationErrors.description,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, description: undefined })),
      },
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 150,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors.date,
        helperText: validationErrors.date,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, date: undefined })),
      },
    },
    {
      accessorKey: "time",
      header: "Time",
      size: 150,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors.time,
        helperText: validationErrors.time,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, time: undefined })),
      },
    },
    {
      accessorKey: "location",
      header: "Location",
      size: 200,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors.location,
        helperText: validationErrors.location,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, location: undefined })),
      },
    },
    {
      accessorKey: "organizer",
      header: "Organizer",
      size: 200,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors.organizer,
        helperText: validationErrors.organizer,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, organizer: undefined })),
      },
    },
    {
      accessorKey: "banner",
      header: "Banner URL",
      Cell: ImageCell,
      size: 200,
      enableEditing: false,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors.banner,
        helperText: validationErrors.banner,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, banner: undefined })),
      },
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getEvents();
      if (response?.data) {
        setRows(response.data);
      }
    } catch (err) {
      setError(err.message || "Something went wrong.");
      snackbar("error", `Failed to fetch events`);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, isRefresh]);

  const onEdit = async (updatedRow) => {
    const { values, row, table } = updatedRow;
    const newValidationErrors = validateEvent(values);

    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});
    const eventId = row.original?.event_id;

    if (eventId) {
      try {
        const response = await updateEvents({ event_id: eventId, data: values });
        console.log("response", JSON.stringify(response, null, 2));
        if (response.success) {
          snackbar("success", "Event updated successfully");
          setIsRefresh(!isRefresh);
          table.setEditingRow(null);
        } else {
          snackbar("error", "Failed to update event");
        }
      } catch (error) {
        console.log(error);
        snackbar("error", "Failed to update event");
      }
    }
  };

  const handleDelete = async () => {
    const eventId = deleteRow?.original?.event_id;
    if (eventId) {
      try {
        const response = await deleteEvent(eventId);
        if (response.success) {
          snackbar("success", response.message);
          setIsRefresh(!isRefresh);
        } else {
          snackbar("error", "Failed to delete event");
        }
      } catch (error) {
        snackbar("error", "Failed to delete event");
      }
    }
    setDialogOpen(false);
  };

  const onDelete = (row) => {
    setDeleteRow(row);
    setDialogOpen(true);
  };

  const toggleAddVisibility = () => {
    setAddVisibility((prev) => !prev);
    fetchData();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                Events
              </MDTypography>
              <MDBox display="flex" alignItems="center">
                <Avatar style={{ backgroundColor: "white", padding: 8, cursor: "pointer" }}>
                  <IconButton style={{ padding: 0 }} onClick={toggleAddVisibility}>
                    {addVisibility ? (
                      <ArrowBack style={{ color: "#1976d2" }} />
                    ) : (
                      <AddIcon style={{ color: "#1976d2" }} />
                    )}
                  </IconButton>
                </Avatar>
              </MDBox>
            </MDBox>

            {addVisibility ? (
              <AddEventForm
                toggleVisibility={toggleAddVisibility}
                onSuccess={() => setIsRefresh(!isRefresh)}
              />
            ) : (
              <MDBox mt={2} ml={2} mr={2}>
                {loading ? (
                  <LoadinSpiner />
                ) : (
                  <>
                    {rows.length > 0 ? (
                      <EditableTable
                        columns={columnsConfig}
                        data={rows}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        validationErrors={validationErrors}
                        setValidationErrors={setValidationErrors}
                      />
                    ) : (
                      <p className="flex justify-center items-center text-gray-500">
                        No event data available.
                      </p>
                    )}
                  </>
                )}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <ConfirmationDialog
        open={dialogOpen}
        title="Delete Event"
        message="Are you sure you want to delete this event? This action cannot be undone."
        onClose={() => setDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </DashboardLayout>
  );
}
