import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardContent, MenuItem, Grid, TextField, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { snackbar } from "components/awesome_snackbar/snackbar";
import { createExamNotification } from "utils/requests/examNotification/createExamNotification";
import { getClassBySchool } from "utils/requests/class/getClass";
import { getSubjectByGradeLevel } from "utils/requests/subjects/subjectRequests";
import { getExamType } from "utils/requests/exam/examrequest";
import MDInput from "components/MDInput";
import { LoadinSpiner } from "components/LoadingSpinner";
import { profile } from "context/profile";
import { getTeachersClassesByTeacherId } from "utils/requests/teachers/assignClass";

const AddExamNotificationForm = ({ onExamLoad }) => {
  const [formData, setFormData] = useState({
    grade_level: "",
    exam_type: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(true);

  const [examDates, setExamDates] = useState([
    {
      date: "",
      numberOfSubjects: 1,
      subjects: [{ subject: "", start_time: "", end_time: "", remarks: "" }],
    },
  ]);

  const [classData, setClassData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [examType, setExamType] = useState([]);
  const [assignClass, setAssignClass] = useState([]);
  const { role, subject_id } = profile;
  const [teacherSubject, setTeacherSubject] = useState([]);

  useEffect(() => {
    const fetchAssignedClassData = async () => {
      if (role === "teacher") {
        const res = await getTeachersClassesByTeacherId();
        if (res.success) {
          const assignedClass = res.data.map((item) => item.class);
          setAssignClass(assignedClass ? assignedClass : []);
          // Extract unique teacher subjects
          const teacherSubject = res.data
            .map((item) => item.staff.subject)
            .filter(
              (subject, index, self) =>
                index === self.findIndex((s) => s.subject_id === subject.subject_id)
            );
          setTeacherSubject(teacherSubject ? teacherSubject : []);
        } else {
          console.log(res.success);
        }
      }
    };
    fetchAssignedClassData();
  }, []);

  useEffect(() => {
    const fetchClassData = async () => {
      const res = await getClassBySchool();
      // console.log("response from server of class", JSON.stringify(res, null, 2));
      if (res.success) {
        setClassData(res.data);
      }
    };
    fetchClassData();
  }, []);

  useEffect(() => {
    const fetchExamTypeData = async () => {
      const res = await getExamType();
      if (res.success) {
        setExamType(res.data);
      }
    };
    fetchExamTypeData();
  }, []);

  useEffect(() => {
    const fetchSubjectData = async () => {
      if (formData.grade_level) {
        const res = await getSubjectByGradeLevel(formData.grade_level);
        console.log(res);
        if (res.success) {
          const subjects = res.data.map((item) => item.subject);
          setSubjectData(subjects);
        } else {
          setSubjectData([]);
        }
      } else {
        setSubjectData([]);
      }
    };
    fetchSubjectData();
  }, [formData.grade_level]);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDateChange = (index, field, value) => {
    const updatedDates = [...examDates];
    updatedDates[index][field] = value;

    if (field === "numberOfSubjects") {
      const numberOfSubjects = parseInt(value, 10) || 1;
      updatedDates[index].subjects = Array.from({ length: numberOfSubjects }, (_, i) => ({
        subject: "",
        start_time: "",
        end_time: "",
        remarks: "",
      }));
    }
    setExamDates(updatedDates);
  };

  const handleSubjectChange = (dateIndex, subjectIndex, field, value) => {
    const updatedDates = [...examDates];
    updatedDates[dateIndex].subjects[subjectIndex][field] = value;
    setExamDates(updatedDates);
  };

  const addDateEntry = () => {
    setExamDates((prev) => [
      ...prev,
      {
        date: "",
        numberOfSubjects: 1,
        subjects: [{ subject: "", start_time: "", end_time: "", remarks: "" }],
      },
    ]);
  };

  const removeDateEntry = (index) => {
    const updatedDates = [...examDates];
    updatedDates.splice(index, 1);
    setExamDates(updatedDates);
  };

  const handleSubmit = async () => {
    // Validate grade level and exam type
    if (!formData.grade_level || !formData.exam_type) {
      snackbar("error", "Grade level and exam type are required.");
      return;
    }

    // Validate each exam date entry
    for (let i = 0; i < examDates.length; i++) {
      const dateEntry = examDates[i];

      if (!dateEntry.date) {
        snackbar("error", `Date is required for entry ${i + 1}`);
        return;
      }

      // Validate subjects
      for (let j = 0; j < dateEntry.subjects.length; j++) {
        const subjectEntry = dateEntry.subjects[j];

        if (!subjectEntry.subject) {
          snackbar("error", `Subject is required for entry ${i + 1}, subject ${j + 1}`);
          return;
        }

        if (!subjectEntry.start_time) {
          snackbar("error", `Start time is required for entry ${i + 1}, subject ${j + 1}`);
          return;
        }

        if (!subjectEntry.end_time) {
          snackbar("error", `End time is required for entry ${i + 1}, subject ${j + 1}`);
          return;
        }

        if (subjectEntry.start_time >= subjectEntry.end_time) {
          snackbar(
            "error",
            `End time must be after start time for entry ${i + 1}, subject ${j + 1}`
          );
          return;
        }
      }
    }

    try {
      setIsSubmitting(false);
      const payload = { ...formData, examDates };
      console.log("data for sending as request body: " + JSON.stringify(payload, null, 2));
      const res = await createExamNotification(payload);
      setIsSubmitting(true);
      if (res.success) {
        snackbar("success", res.message);
        onExamLoad();
        setFormData({ grade_level: "", exam_type: "" });
        setExamDates([
          {
            date: "",
            numberOfSubjects: 1,
            subjects: [{ subject: "", start_time: "", end_time: "", remarks: "" }],
          },
        ]);
      } else {
        snackbar("error", res.message);
      }
    } catch (error) {
      setIsSubmitting(true);
      console.error("Error creating exam notification:", error);
      snackbar("error", "Failed to create exam notification.");
    }
  };

  console.log(JSON.stringify(teacherSubject));
  console.log("classData", JSON.stringify(classData, null, 2));

  return (
    <Card>
      <CardContent>
        <MDBox
          pt={2}
          px={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <MDTypography variant="h6">Add Exam Notification</MDTypography>
        </MDBox>
        <MDBox display="flex" flexDirection="column" gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="Grade Level"
                value={formData.grade_level}
                onChange={(e) => handleInputChange("grade_level", e.target.value)}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "44px",
                  },
                }}
              >
                {Array.from(
                  new Set(
                    (role === "teacher" ? assignClass : classData).map((cls) => cls.grade_level)
                  )
                ).map((grade) => (
                  <MenuItem key={grade} value={grade}>
                    {grade}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                select
                label="Exam Type"
                value={formData.exam_type}
                onChange={(e) => handleInputChange("exam_type", e.target.value)}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "44px",
                  },
                }}
              >
                {examType.map((exam) => (
                  <MenuItem key={exam.exam_id} value={exam.exam_name}>
                    {exam.exam_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          {examDates.map((dateEntry, dateIndex) => (
            <MDBox key={dateIndex} mt={2}>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} md={6}>
                  <MDInput
                    label="Date"
                    type="date"
                    value={dateEntry.date}
                    onChange={(e) => handleDateChange(dateIndex, "date", e.target.value)}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <MDInput
                    label="Number of Subjects"
                    type="number"
                    value={dateEntry.numberOfSubjects}
                    onChange={(e) =>
                      handleDateChange(dateIndex, "numberOfSubjects", e.target.value)
                    }
                    required
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={1}
                  style={{ display: `${role === "teacher" ? "block" : "none"}` }}
                >
                  {dateIndex === 0 ? (
                    <IconButton onClick={addDateEntry} color="primary">
                      <AddIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => removeDateEntry(dateIndex)} color="secondary">
                      <RemoveIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              {dateEntry.subjects.map((subjectEntry, subjectIndex) => (
                <Grid container spacing={2} key={subjectIndex} marginBottom={2}>
                  <Grid item xs={12} md={5}>
                    <TextField
                      fullWidth
                      select
                      label="Subject"
                      value={subjectEntry.subject}
                      onChange={(e) =>
                        handleSubjectChange(dateIndex, subjectIndex, "subject", e.target.value)
                      }
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "44px",
                        },
                      }}
                      disabled={formData.grade_level === ""}
                    >
                      {(role === "teacher" ? teacherSubject : subjectData).map((subject) => (
                        <MenuItem key={subject.subject_id} value={subject.name}>
                          {subject.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <MDInput
                      label="Start Time"
                      type="time"
                      value={subjectEntry.start_time}
                      onChange={(e) =>
                        handleSubjectChange(dateIndex, subjectIndex, "start_time", e.target.value)
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <MDInput
                      label="End Time"
                      type="time"
                      value={subjectEntry.end_time}
                      onChange={(e) =>
                        handleSubjectChange(dateIndex, subjectIndex, "end_time", e.target.value)
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={11} md={11}>
                    <TextField
                      label="Remarks"
                      multiline
                      rows={3}
                      value={formData.remarks}
                      onChange={(e) =>
                        handleSubjectChange(dateIndex, subjectIndex, "remarks", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ))}
            </MDBox>
          ))}
          <MDBox mt={4}>
            {isSubmitting ? (
              <MDButton variant="contained" color="success" onClick={handleSubmit}>
                Submit
              </MDButton>
            ) : (
              <LoadinSpiner />
            )}
          </MDBox>
        </MDBox>
      </CardContent>
    </Card>
  );
};

AddExamNotificationForm.propTypes = {
  onExamLoad: PropTypes.func.isRequired,
};

export default AddExamNotificationForm;
