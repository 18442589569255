import { useEffect, useState, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import NotificationCard from "components/notificationcard";
import { notificationGetter } from "context/notification";
import { getNotification } from "utils/requests/notification/notification";
import { LoadinSpiner } from "components/LoadingSpinner";
import { getAllRequest } from "utils/requests/request/createRequest";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [requests, setRequests] = useState([]);
  const [isFetchingNotifications, setIsFetchingNotifications] = useState(true);
  const [isFetchingRequests, setIsFetchingRequests] = useState(true);
  const [notificationCount, setNotificationCount] = useState(0);
  const [re_render, setReRender] = useState(false);
  const [error, setError] = useState(null);

  const fetchNotifications = async () => {
    try {
      setIsFetchingNotifications(true);
      await getNotification();
      setNotifications(notificationGetter());
      setNotificationCount(notificationGetter.length);
    } catch (error) {
      setError("Failed to load notifications.");
    } finally {
      setIsFetchingNotifications(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchRequests = async () => {
    try {
      setIsFetchingRequests(true);
      const res = await getAllRequest();
      if (res.success) {
        setRequests(res.data);
        setNotificationCount((prevCount) => prevCount + res.data.length);
      }
    } catch (error) {
      console.error("Error fetching requests:", error);
      setError("Failed to load requests.");
    } finally {
      setIsFetchingRequests(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  const handleStatusChange = () => {
    fetchNotifications();
    fetchRequests();
    setReRender(true);
  };

  const renderNotifications = useMemo(() => {
    return notifications.map((notification) => (
      <MDBox pt={2} px={2} key={notification.request_id}>
        <NotificationCard
          message={notification.message}
          name={notification.sender_name}
          date={notification.sent_at}
          role={notification.sender_role}
          request_id={notification.request_id}
          onStatusChange={handleStatusChange}
        />
      </MDBox>
    ));
  }, [notifications]);

  const renderRequests = useMemo(() => {
    return requests.map((request) => (
      <MDBox pt={2} px={2} key={request.notification_id}>
        <NotificationCard
          message={request.message}
          name={`${request.staff.first_name} ${request.staff.last_name}`}
          date={request.createdAt}
          role={request.staff.role}
          request_id={request.notification_id}
          exams={request.exams}
          type={request.type}
          onStatusChange={handleStatusChange}
        />
      </MDBox>
    ));
  }, [requests]);

  return (
    <DashboardLayout>
      <DashboardNavbar re_render={re_render} />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant="h5">Alerts</MDTypography>
                {isFetchingNotifications || isFetchingRequests ? (
                  <LoadinSpiner />
                ) : requests.length > 0 || notifications.length > 0 ? (
                  <>
                    {renderNotifications}
                    {renderRequests}
                  </>
                ) : (
                  <MDTypography
                    variant="p"
                    style={{ marginTop: "10px", fontSize: "14px", color: "gray" }}
                  >
                    You haven’t notification yet
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Notifications;
