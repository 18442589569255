import React, { useState, useRef, useEffect } from "react";
import { Card, CardContent, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { snackbar } from "components/awesome_snackbar/snackbar";
import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { createClass } from "utils/requests/class/createClass";
import { X } from "lucide-react";
function Classes() {
  const [rows, setRows] = useState([
    { grade_level: "", section_start: "", section_end: "", capacity: "", building_name: "" },
  ]);
  const [loading, setLoading] = useState(false);

  const gradeInputRef = useRef(null);
  const startSectionRef = useRef(null);
  const endSectionRef = useRef(null);

  useEffect(() => {
    if (gradeInputRef.current) {
      gradeInputRef.current.focus();
    }
  }, []);

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      { grade_level: "", section_start: "", section_end: "", capacity: "", building_name: "" },
    ]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const validateSection = (section) => {
    if (section.length !== 1) {
      return "You can enter just one letter.";
    }
    if (!/^[a-zA-Z]$/.test(section)) {
      return "Cannot enter numbers or special characters.";
    }
    return null;
  };

  const validateCapacity = (capacity) => {
    if (!capacity || isNaN(capacity) || capacity <= 0) {
      return "Capacity must be a positive number.";
    }
    return null;
  };

  const getCharRange = (start, end) => {
    const range = [];
    let startChar = start.toUpperCase().charCodeAt(0);
    let endChar = end.toUpperCase().charCodeAt(0);

    if (startChar > endChar) {
      return "Error! Start section must be before or equal to end section.";
    }

    for (let i = startChar; i <= endChar; i++) {
      range.push(String.fromCharCode(i));
    }
    return range;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      for (let i = 0; i < rows.length; i++) {
        const { grade_level, section_start, section_end, capacity, building_name } = rows[i];

        if (!grade_level || !section_start || !building_name || !capacity) {
          await snackbar("error", `Error in row ${i + 1}! All fields are required.`);
          setLoading(false);
          return;
        }

        const startValidationError = validateSection(section_start);
        if (startValidationError) {
          await snackbar("error", `Error in row ${i + 1}! ${startValidationError}`);
          setLoading(false);
          return;
        }

        const capacityError = validateCapacity(capacity);
        if (capacityError) {
          await snackbar("error", `Error in row ${i + 1}! ${capacityError}`);
          setLoading(false);
          return;
        }

        if (section_end) {
          const endValidationError = validateSection(section_end);
          if (endValidationError) {
            await snackbar("error", `Error in row ${i + 1}! ${endValidationError}`);
            setLoading(false);
            return;
          }

          const sectionRange = getCharRange(section_start, section_end);
          if (typeof sectionRange === "string") {
            await snackbar("error", `Error in row ${i + 1}! ${sectionRange}`);
            setLoading(false);
            return;
          }
        }

        const classData = {
          building_name: building_name,
          capacity: parseInt(capacity),
          section_start: section_start,
          section_end: section_end || null,
          grade_level: parseInt(grade_level),
        };

        const response = await createClass(classData);
        if (response.success) {
          await snackbar("success", response.message);
        }
      }
    } catch (error) {
      await snackbar("error", response.message);
    }

    setRows([
      { grade_level: "", section_start: "", section_end: "", capacity: "", building_name: "" },
    ]);
    if (gradeInputRef.current) {
      gradeInputRef.current.focus();
    }

    setLoading(false);
  };

  const handleKeyDown = (e, refToFocusNext) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (refToFocusNext) {
        refToFocusNext.current.focus();
      } else {
        handleSubmit();
      }
    }
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Card sx={{ height: "auto", width: "100%" }}>
      <CardContent>
        <MDBox
          pt={2}
          px={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <MDTypography variant="h6" fontWeight="medium">
            Create Class
          </MDTypography>
          <IconButton color="info" onClick={handleAddRow}>
            <AddIcon />
          </IconButton>
        </MDBox>

        {rows.map((row, index) => (
          <MDBox
            key={index}
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            gap={2}
            px={2}
            mb={2}
          >
            <MDInput
              type="number"
              label="Grade"
              placeholder="Enter the grade (e.g., 1, 2, 3)"
              value={row.grade_level}
              onChange={(e) => handleInputChange(index, "grade_level", e.target.value)}
              inputRef={gradeInputRef}
              onKeyDown={(e) => handleKeyDown(e, startSectionRef)}
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
            />
            <MDInput
              type="text"
              label="Start Section"
              placeholder="Enter the start section (e.g., A)"
              value={row.section_start}
              onChange={(e) => handleInputChange(index, "section_start", e.target.value)}
              inputRef={startSectionRef}
              onKeyDown={(e) => handleKeyDown(e, endSectionRef)}
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
            />
            <MDInput
              type="text"
              label="End Section (Optional)"
              placeholder="Enter the end section (e.g., D)"
              value={row.section_end}
              inputRef={endSectionRef}
              onChange={(e) => handleInputChange(index, "section_end", e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <MDInput
              type="text"
              label="Building Name"
              placeholder="Enter the building name"
              value={row.building_name}
              onChange={(e) => handleInputChange(index, "building_name", e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
            />
            <MDInput
              type="number"
              label="Capacity"
              placeholder="Enter the class capacity"
              value={row.capacity}
              onChange={(e) => handleInputChange(index, "capacity", e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
            />
            {index > 0 && (
              <IconButton color="error" onClick={() => handleRemoveRow(index)}>
                <X />
              </IconButton>
            )}
          </MDBox>
        ))}

        <MDBox display="flex" justifyContent="flex-end" px={2} mt={2}>
          <MDButton variant="contained" color="info" onClick={handleSubmit} disabled={loading}>
            {loading ? "Loading..." : "Submit"}
          </MDButton>
        </MDBox>
      </CardContent>
    </Card>
  );
}

export default Classes;
