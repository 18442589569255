import { useState, useEffect } from "react";

import GitHubButton from "react-github-btn";

import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import CloseIcon from "@mui/icons-material/Close";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
} from "context";
import PasswordConfirmation from "layouts/authentication/forgottenpassword/newPassword";
import { getSetting } from "utils/requests/setting/getSetting";
import { updateSetting } from "utils/requests/setting/createSetting";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { profile } from "context/profile";
import { getSettingLists } from "utils/requests/setting/getSetting";

function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator, fixedNavbar, transparentSidenav, whiteSidenav, darkMode } = controller;
  const [disabled, setDisabled] = useState(false);
  const [settings, setSettings] = useState([]);
  const [settingList, setSettingList] = useState([]);
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  useEffect(() => {
    const fetSettingList = async () => {
      const res = await getSettingLists();
      if (res.success) {
        setSettingList(res.data);
      }
    };
    fetSettingList();
  }, []);

  useEffect(() => {
    const fetSettings = async () => {
      const res = await getSetting();
      if (res.success) {
        setSettings(res.data);
      }
    };
    fetSettings();
  }, []);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleTransparentSidenav = () => {
    setTransparentSidenav(dispatch, true);
    setWhiteSidenav(dispatch, false);
  };
  const handleWhiteSidenav = () => {
    setWhiteSidenav(dispatch, true);
    setTransparentSidenav(dispatch, false);
  };
  const handleDarkSidenav = () => {
    setWhiteSidenav(dispatch, false);
    setTransparentSidenav(dispatch, false);
  };
  const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    palette: { white, dark, background },
    borders: { borderWidth },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? background.sidenav : white.main,
    color: darkMode ? white.main : dark.main,
    border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? background.sidenav : white.main,
      color: darkMode ? white.main : dark.main,
      border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
    },
  });

  // sidenav type active button styles
  const sidenavTypeActiveButtonStyles = ({
    functions: { pxToRem, linearGradient },
    palette: { white, gradients, background },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
    color: darkMode ? background.sidenav : white.main,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
      color: darkMode ? background.sidenav : white.main,
    },
  });

  const handleAutoApprove = async (settingId, currentValue) => {
    // Determine the new value to set
    const newValue = !currentValue;

    try {
      // Call the API to update or create the setting
      const res = await updateSetting(settingId, newValue);

      if (res.success) {
        if (res.message === "Setting created successfully") {
          setSettings((prevSettings) => [...prevSettings, res.data]);
        } else if (res.message === "Setting updated successfully") {
          setSettings((prevSettings) =>
            prevSettings.map((setting) =>
              setting.setting_id === settingId ? { ...setting, value: newValue } : setting
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Setting</MDTypography>
          <MDTypography variant="body2" color="text">
            See our dashboard options.
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

      <MDBox pt={0.5} pb={3} px={3}>
        <MDBox mt={0} lineHeight={1}>
          <MDTypography variant="h6">Sidenav Type</MDTypography>
          <MDTypography variant="button" color="text">
            Choose between different sidenav types.
          </MDTypography>
          <MDBox
            sx={{
              display: "flex",
              mt: 2,
              mr: 1,
            }}
          >
            <MDButton
              color="dark"
              variant="gradient"
              onClick={handleDarkSidenav}
              disabled={disabled}
              fullWidth
              sx={
                !transparentSidenav && !whiteSidenav
                  ? sidenavTypeActiveButtonStyles
                  : sidenavTypeButtonsStyles
              }
            >
              Dark
            </MDButton>
            <MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
              <MDButton
                color="dark"
                variant="gradient"
                onClick={handleTransparentSidenav}
                disabled={disabled}
                fullWidth
                sx={
                  transparentSidenav && !whiteSidenav
                    ? sidenavTypeActiveButtonStyles
                    : sidenavTypeButtonsStyles
                }
              >
                Transparent
              </MDButton>
            </MDBox>
            <MDButton
              color="dark"
              variant="gradient"
              onClick={handleWhiteSidenav}
              disabled={disabled}
              fullWidth
              sx={
                whiteSidenav && !transparentSidenav
                  ? sidenavTypeActiveButtonStyles
                  : sidenavTypeButtonsStyles
              }
            >
              White
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">Light / Dark</MDTypography>

          <Switch checked={darkMode} onChange={handleDarkMode} />
        </MDBox>
        <Divider />
        <MDButton variant="contained" color="info" onClick={handleClickOpen}>
          Change Password
        </MDButton>
        <Divider />
        {profile.role === "admin" && (
          <>
            {settingList.length > 0 &&
              settingList.map((settingItem) => {
                const existingSetting = settings?.find(
                  (setting) => setting.setting_id === settingItem.setting_id
                );

                // Merge the properties from `settingItem` into `existingSetting`
                const mergedSetting = {
                  ...settingItem,
                  ...existingSetting,
                  value: existingSetting?.value ?? false,
                };

                return (
                  <MDBox key={mergedSetting.setting_id}>
                    <MDBox
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <MDTypography
                        style={{ color: "#BDBDBD", fontSize: "14px", textTransform: "capitalize" }}
                        variant="h6"
                      >
                        {mergedSetting.setting_name}
                      </MDTypography>
                      <Switch
                        checked={mergedSetting.value}
                        onChange={() =>
                          handleAutoApprove(mergedSetting.setting_id, mergedSetting.value)
                        }
                      />
                    </MDBox>
                    <Divider style={{ marginTop: "4px", color: "gray" }} />
                  </MDBox>
                );
              })}
          </>
        )}
      </MDBox>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          Change Password
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PasswordConfirmation handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </ConfiguratorRoot>
  );
}

export default Configurator;
