import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ArrowBack } from "@mui/icons-material";
import { Avatar, Grid, IconButton } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import AddIcon from "@mui/icons-material/Add";
import GenerateExamForm from "./generateExamForm";
import MDButton from "components/MDButton";
import { useState } from "react";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";

const GenerateExam = () => {
  const [addVisibility, setAddVisibility] = useState(false);
  const [questions, setQuestions] = useState([]);

  const handleQuestionsGenerated = (generatedQuestions) => {
    if (!generatedQuestions || !generatedQuestions.data) {
      console.error("Invalid data received from server:", generatedQuestions);
      return;
    }
    const parsedQuestions = parseServerResponse(generatedQuestions.data);
    setQuestions(parsedQuestions);
    setAddVisibility(false);
  };

  // Function to parse server response
  const parseServerResponse = (data) => {
    if (!data || typeof data !== "string") {
      console.error("Invalid data provided to parser:", data);
      return [];
    }

    const lines = data.split("\n").filter((line) => line.trim() !== "");
    const questions = [];
    let currentQuestion = null;

    lines.forEach((line) => {
      if (line.match(/^\d+\./)) {
        // New question
        if (currentQuestion) {
          questions.push(currentQuestion);
        }
        currentQuestion = { text: line.trim(), options: [] };
      } else if (line.match(/^\(.*?\)/)) {
        // Option for multiple-choice
        currentQuestion?.options?.push(line.trim());
      } else if (!line.startsWith("**")) {
        // Additional text for the current question
        if (currentQuestion) {
          currentQuestion.text += ` ${line.trim()}`;
        }
      }
    });

    // Add the last question
    if (currentQuestion) {
      questions.push(currentQuestion);
    }

    return questions;
  };

  const handleDownload = () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: questions.map(
            (q) =>
              new Paragraph({
                children: [
                  new Paragraph(q.text),
                  ...(q.options || []).map((option) => new Paragraph(option)),
                ],
              })
          ),
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "ExamQuestions.docx");
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                Exam Sheet
              </MDTypography>
              <MDBox display="flex" alignItems="center">
                <Avatar style={{ backgroundColor: "white", padding: 8, cursor: "pointer" }}>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() => setAddVisibility(!addVisibility)}
                  >
                    {addVisibility ? (
                      <ArrowBack style={{ color: "#1976d2" }} />
                    ) : (
                      <AddIcon style={{ color: "#1976d2" }} />
                    )}
                  </IconButton>
                </Avatar>
              </MDBox>
            </MDBox>
            {addVisibility ? (
              <GenerateExamForm onQuestionsGenerated={handleQuestionsGenerated} />
            ) : (
              <MDBox mt={2} ml={2} mr={2}>
                {questions.length > 0 ? (
                  <>
                    <MDBox mb={2}>
                      <MDTypography variant="h6">Generated Questions:</MDTypography>
                      {questions.map((q, index) => (
                        <MDBox key={index} mb={1}>
                          <MDTypography>{`${q.text}`}</MDTypography>
                          {q.options &&
                            q.options.map((opt, i) => (
                              <MDTypography key={i} ml={2}>{`${opt}`}</MDTypography>
                            ))}
                        </MDBox>
                      ))}
                    </MDBox>
                    <MDButton color="info" variant="contained" onClick={handleDownload}>
                      Download Questions
                    </MDButton>
                  </>
                ) : (
                  <MDTypography>No questions generated yet.</MDTypography>
                )}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};
export default GenerateExam;
