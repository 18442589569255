// ExamResult.js

import React, { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import ResultTable from "./ResultTable";
import { getExamType } from "utils/requests/exam/examrequest";
import { getResults } from "utils/requests/result/getResult";
import MDTypography from "components/MDTypography";
import { LoadinSpiner } from "components/LoadingSpinner";
import axios from "axios";
import { Avatar, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import StudentAdd from "layouts/OverLayForm";
import { profile } from "context/profile";
import { getTeachersClassesByTeacherId } from "utils/requests/teachers/assignClass";

export default function ExamResult() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addVisibility, setAddVisibility] = useState(false);
  const [isRerender, setIsRerender] = useState(false);
  const [teacherClass, setTeacherClass] = useState([]);

  console.log(profile);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const examTypesResponse = await getExamType();
        const resultsResponse = await getResults();
        // // console.log("result returned", JSON.stringify(resultsResponse, null, 2));

        if (examTypesResponse.success) {
          const examTypes = examTypesResponse.data;
          const dynamicColumns = [
            {
              accessorKey: "studentName",
              header: "Student Name",
              size: 250,
            },
            ...examTypes.map((examType) => ({
              accessorKey: examType.exam_name.charAt(0).toUpperCase() + examType.exam_name.slice(1),
              header: examType.exam_name.charAt(0).toUpperCase() + examType.exam_name.slice(1),
              size: 160,
            })),
            {
              accessorKey: "total",
              header: "Total",
            },
          ];
          setColumns(dynamicColumns);

          const studentResultsMap = {};
          resultsResponse.data.forEach((result) => {
            const studentId = result.student.student_id;
            const studentName = `${result.student.first_name} ${result.student.last_name}`;
            const examName =
              result.exam.exam_name.charAt(0).toUpperCase() + result.exam.exam_name.slice(1);

            if (!studentResultsMap[studentId]) {
              studentResultsMap[studentId] = {
                studentName,
                total: 0,
                ...examTypes.reduce((acc, examType) => {
                  acc[examType.exam_name.charAt(0).toUpperCase() + examType.exam_name.slice(1)] =
                    "";
                  return acc;
                }, {}),
              };
            }

            studentResultsMap[studentId].total += result.mark;
            studentResultsMap[studentId][examName] = result.mark;
          });

          const filledRows = Object.values(studentResultsMap);
          setRows(filledRows);
        } else {
          throw new Error("Failed to fetch exam types: " + examTypesResponse.data.message);
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response) {
            // Server responded with a status other than 2xx
            console.error("Server error:", err.response.data.message || err.response.statusText);
            setError(err.response.data.message || "Server error occurred");
          } else if (err.request) {
            // Request was made but no response received
            console.error("Network error: No response received from server.");
            setError("Network error: Please check your internet connection.");
          } else {
            // Unexpected error in setting up the request
            console.error("Request error:", err.message);
            setError("Unexpected error occurred: " + err.message);
          }
        } else {
          // Non-Axios error handling
          console.error("Unexpected error:", err);
          setError("An unexpected error occurred. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchTeacherClasses = async () => {
      try {
        const response = await getTeachersClassesByTeacherId();
        // // console.log("response: " + JSON.stringify(response.data, null, 2));
        setTeacherClass(response.data);
      } catch (error) {
        console.error("Error fetching teacher classes:", error);
        setTeacherClass([]);
      }
    };
    fetchTeacherClasses();
  }, []);
  const toggleAddVisibility = () => {
    setAddVisibility((prev) => !prev);
    setIsRerender(!isRerender);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                Results
              </MDTypography>
              {profile.subject_id &&
                teacherClass.length > 0 &&
                profile.subject_id.toLowerCase() !== "no subject assigned" && (
                  <MDBox display="flex" alignItems="center">
                    <Avatar style={{ backgroundColor: "white", padding: 8, cursor: "pointer" }}>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={toggleAddVisibility}
                        aria-label="toggle add class"
                      >
                        {addVisibility ? (
                          <ArrowBack style={{ color: "#1976d2" }} />
                        ) : (
                          <AddIcon style={{ color: "#1976d2" }} />
                        )}
                      </IconButton>
                    </Avatar>
                  </MDBox>
                )}
            </MDBox>
            {addVisibility ? (
              <StudentAdd />
            ) : (
              <MDBox pb={10}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <MDBox mt={2} ml={2} mr={2}>
                      {loading ? (
                        <LoadinSpiner />
                      ) : (
                        <>
                          {error && (
                            <p className="text-gray-600 font-normal text-center capitalize">
                              {error.message}
                            </p>
                          )}
                          {columns && rows && columns.length > 0 && rows.length > 0 ? (
                            <ResultTable columns={columns} rows={rows} />
                          ) : (
                            <p className="text-gray-600 font-normal text-center capitalize">
                              No exam data available.
                            </p>
                          )}
                        </>
                      )}
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
