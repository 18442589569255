import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Bubble from "../../components/bubble/bubble"; // Assuming the Bubble component is in the same folder
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
// import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import ReplayIcon from "@mui/icons-material/Replay";
import { Resend } from "utils/requests/message/createmessage";
import { snackbar } from "components/awesome_snackbar/snackbar";
// import { Tooltip } from "@mui/material";

function BroadcastinghistoryCard({ message, status, message_id, resend }) {
  useEffect(() => {
    // Chat bubble initialization if needed
  }, []);

  const handleResend = async () => {
    const data = {
      message_id,
    };
    const res = await Resend(data);
    if (res.success) {
      // Handle successful resend
      snackbar("success", res.message);
      resend(true);
    } else {
      // Handle failed resend
      snackbar("error", res.message);
    }
  };

  // Define the status icon and text based on the message status
  let statusIcon;

  if (status === "sent") {
    statusIcon = <DoneAllIcon className="inline text-green-500 " />;
  } else if (status === "pending") {
    statusIcon = <DoneIcon className="inline text-green-500 " />;
  } else if (status === "failed") {
    statusIcon = (
      <div className="flex space-x-4 mt-2 items-center relative">
        <span className="text-[12px] text-red-300 opacity-90">failed send message</span>
        <button onClick={handleResend} className="flex">
          <ReplayIcon className="inline text-gray-400 " />
        </button>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col">
      <Bubble
        message={
          <div className="hover:cursor-pointer flex space-x-4 relative pb-3">
            <span> {`${message} `}</span>
          </div>
        }
        status={status}
        message_id={message_id}
      />
      <span className="flex justify-end items-center absolute -bottom-3 right-0">{statusIcon}</span>
    </div>
  );
}

BroadcastinghistoryCard.propTypes = {
  message: PropTypes.string.isRequired,
  status: PropTypes.oneOf(["sent", "pending", "failed"]).isRequired,
  message_id: PropTypes.string.isRequired,
  resend: PropTypes.func.isRequired,
};

export default BroadcastinghistoryCard;
