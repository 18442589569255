import { axiosInstanceAuth } from "config/axios-config";
export const generateQuestion = async (data) => {
  const response = {
    data: null,
    message: "",
    success: false,
  };
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const res = await axiosAuth.post("/question", data);
    if (res.data.success) {
      response.data = res.data.data;
      response.message = res.data.message;
      response.success = res.data.success;
    } else {
      response.message = res.data.message;
    }
  } catch (error) {
    response.message = error?.response?.data?.message;
  }
  return response;
};
