/* eslint-disable react/prop-types */
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import PhoneInput from "react-phone-input-2";
import MDButton from "components/MDButton";
import { FileUpload } from "components/FileUpload";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import "react-day-picker/dist/style.css";
import { createStudents, updateStudents } from "../../..//utils/requests/students/studentsget";
import { snackbar } from "../../../components/awesome_snackbar/snackbar";
import { getClassBySchool } from "utils/requests/class/getClass";
import { Typography } from "@mui/material";
import { validatePhone } from "helper/phoneValidation";
const StudentEditorModal = ({ onClose, teacher, routeName }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [gradeSection, setGradeSection] = useState("");
  const [phone, setPhone] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [dateValue, setDateValue] = useState(dayjs());
  const [gender, setGender] = useState("");
  const [errors, setErrors] = useState({});
  const [classData, setClassData] = useState([]);
  const [email, setEmail] = useState("");
  const [filteredSections, setFilteredSections] = useState([]);

  useEffect(() => {
    const fetchClassData = async () => {
      const result = await getClassBySchool();
      if (result.success) {
        setClassData(result.data);
      }
      setErrors(result.errors || {});
    };
    fetchClassData();
  }, []);

  useEffect(() => {
    let phone = teacher?.parent?.phone_number;
    if (phone && phone.startsWith("0")) {
      phone = "+251" + phone.slice(1);
    }
    setFirstName(teacher.first_name || "");
    setLastName(teacher.last_name || "");
    setMiddleName(teacher.middle_name || "");
    setParentEmail(teacher?.parent?.email || "");
    setParentFirstName(teacher?.parent?.first_name || "");
    setParentLastName(teacher?.parent?.last_name || "");
    setGradeLevel(teacher?.class?.grade_level || "");
    setGradeSection(teacher?.class?.section || "");
    setPhone(phone || "");
    setDateValue(teacher.birth_date || "");
    setGender(teacher.gender || "");
    setEmail(teacher.email || "");

    return () => {
      onClose();
    };
  }, [routeName, onClose]);

  const validateFields = () => {
    const newErrors = {};
    const sevenYearsAgo = dayjs().subtract(7, "years");
    if (!firstName) newErrors.firstName = "First name is required";
    if (!middleName) newErrors.middleName = "Middle name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    if (!parentFirstName) newErrors.parentFirstName = "Parent first name is required";
    if (!parentLastName) newErrors.parentLastName = "Parent last name is required";
    if (!gradeLevel) newErrors.gradeLevel = "Grade level is required";
    if (!gradeSection) newErrors.gradeSection = "Grade section is required";
    const phoneError = validatePhone(phone);
    if (phoneError) newErrors.phone = phoneError;
    if (dayjs(dateValue).isAfter(sevenYearsAgo)) {
      newErrors.dateOfBirth = "students year must be at least 7 years ago";
    }
    if (!gender) newErrors.gender = "Gender is required";
    // Parent email validation (optional)
    if (!parentEmail) {
      newErrors.parentEmail = "Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(parentEmail)) {
      newErrors.parentEmail = "Invalid email format";
    }

    setErrors(newErrors);
    return newErrors;
  };
  // const handleGradeLevelChange = (e) => {
  //   const selectedGrade = e.target.value || gradeLevel;
  //   if (selectedGrade !== gradeLevel) {
  //     setGradeLevel(selectedGrade);
  //     setGradeSection("");
  //   }

  //   const sectionsForGrade = classData.filter((cls) => cls.grade_level === selectedGrade);

  //   console.log("section grade: " + sectionsForGrade);
  //   setFilteredSections(sectionsForGrade);
  // };
  const handleSubmitStudent = async () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      return;
    } else {
      setErrors({});
      await dataOrganization();
    }
  };

  const dataOrganization = async () => {
    try {
      const genderElement = document.querySelector('input[name="row-radio-buttons-group"]:checked');
      const formData = {
        student_id: teacher.student_id,
        parent_id: teacher.parent_id,
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        parent_first_name: parentFirstName,
        parent_last_name: parentLastName,
        grade_level: gradeLevel,
        section: gradeSection,
        phone_number: phone,
        parent_email: parentEmail,
        gender: genderElement ? genderElement.value : "other",
        birth_date: !isNaN(new Date(dateValue))
          ? new Date(dateValue).toISOString().split("T")[0]
          : "2002-01-01",
      };
      const response = await updateStudents(formData);

      if (response.data.success) {
        snackbar("success", response.data.message);
        onClose();
      } else {
        snackbar("error", response.data.message);
      }
    } catch (error) {
      snackbar("error", "An error occurred while creating the student profile.");
    }
  };

  const handleKeyPress = (event, nextFieldId) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById(nextFieldId)?.focus();
    }
  };

  return (
    <MDBox display="flex" justifyContent="space-around">
      <MDBox pt={4} flex={1} mx={3}>
        <MDBox mb={2}>
          <MDInput
            id="first-name"
            label="First name *"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event, "last-name")}
            style={{ width: "45%" }}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <MDInput
            id="middel-name"
            type="text"
            label="Middle name *"
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event, "parent-first-name")}
            style={{ width: "45%", marginLeft: "5%" }}
            error={!!errors.middleName}
            helperText={errors.middleName}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            id="last-name"
            type="text"
            label="Last name *"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event, "parent-last-name")}
            style={{ width: "45%" }}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
        </MDBox>
        <MDBox>
          <MDInput
            id="parent-first-name"
            label="Parent First name *"
            value={parentFirstName}
            onChange={(e) => setParentFirstName(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event, "parent-last-name")}
            style={{ width: "45%" }}
            error={!!errors.parentFirstName}
            helperText={errors.parentFirstName}
          />
          <MDInput
            id="parent-last-name"
            type="text"
            label="Parent Last name *"
            value={parentLastName}
            onChange={(e) => setParentLastName(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event, "grade_level")}
            style={{ width: "45%", marginLeft: "5%" }}
            error={!!errors.parentLastName}
            helperText={errors.parentLastName}
          />
        </MDBox>
        <MDBox mb={2}>
          <select
            id="gradeLevel"
            name="gradeLevel"
            value={gradeLevel}
            onChange={(e) => setGradeLevel(e.target.value)}
            style={{
              width: "45%",
              fontSize: "14px",
              padding: "10px",
              borderRadius: "5px",
              color: "grey",
              border: "1px solid lightgrey",
              marginTop: 10,
            }}
          >
            <option value={gradeSection}>Grade *</option>
            {Array.from(new Set(classData.map((cls) => cls.grade_level))).map((grade) => (
              <option key={grade} value={grade}>
                {grade}
              </option>
            ))}
          </select>
          {errors.gradeLevel && (
            <div style={{ color: "red", marginTop: 5 }}>{errors.gradeLevel}</div>
          )}

          <select
            id="gradeSection"
            name="gradeSection"
            value={gradeSection}
            onChange={(e) => setGradeSection(e.target.value)}
            style={{
              width: "45%",
              fontSize: "14px",
              padding: "10px",
              borderRadius: "5px",
              color: "grey",
              border: "1px solid lightgrey",
              marginTop: 20,
              marginLeft: "5%",
            }}
          >
            <option value="">Section *</option>
            {Array.from(new Set(classData.map((cls) => cls.section))).map((section) => (
              <option key={section} value={section}>
                {section}
              </option>
            ))}
          </select>
          {errors.gradeSection && (
            <div style={{ color: "red", marginTop: 5 }}>{errors.gradeSection}</div>
          )}
        </MDBox>
        <MDBox mb={2} mt={2}>
          <PhoneInput
            id="phone_input"
            country={"et"}
            value={phone}
            placeholder="+ (251) 900-000-00"
            onChange={(numbers) => {
              setPhone(numbers);
            }}
            preferredCountries={["et", "ke", "dj", "sd"]}
            enableSearch={true}
            countryCodeEditable={true}
            inputStyle={{
              width: "95%",
              height: "45px",
            }}
            autocompleteSearch={true}
            onKeyPress={(event) => handleKeyPress(event, "parent-email")}
            isInvalid={!!errors.phone}
          />
          {errors.phone && <div style={{ color: "red", marginTop: 5 }}>{errors.phone}</div>}
        </MDBox>
        <MDBox>
          <MDInput
            id="parent-email"
            type="email"
            label="Parent email"
            value={parentEmail}
            onChange={(e) => setParentEmail(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event, "phone_input")}
            style={{ width: "95%", marginBottom: "10px" }}
            error={!!errors.parentEmail}
            helperText={errors.parentEmail}
          />
        </MDBox>
        <MDBox>
          <Typography component="p" style={{ fontSize: "14px" }}>
            Birth Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                defaultValue={dateValue}
                views={["year", "month", "day"]}
                onChange={(date) => setDateValue(date)}
              />
            </DemoContainer>
            {errors.dateOfBirth && (
              <div style={{ color: "red", marginTop: 5, fontSize: "14px" }}>
                {errors.dateOfBirth}
              </div>
            )}
          </LocalizationProvider>
        </MDBox>

        <MDBox mb={2} mt={2}>
          <Typography component="p" style={{ fontSize: "14px" }}>
            Gender
          </Typography>
          <FormControl style={{}}>
            <RadioGroup
              aria-label="Gender"
              row
              name="row-radio-buttons-group"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <FormControlLabel value="f" control={<Radio />} label="Female" />
              <FormControlLabel value="m" control={<Radio />} label="Male" />
            </RadioGroup>
          </FormControl>
          {errors.gender && <div style={{ color: "red", marginTop: 5 }}>{errors.gender}</div>}
        </MDBox>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: "10%",
          }}
        >
          <MDButton
            variant="gradient"
            color="info"
            style={{ width: "25%", marginRight: "10px" }}
            onClick={handleSubmitStudent}
          >
            save edit
          </MDButton>
          <MDButton onClick={onClose} variant="gradient" color="primary" style={{ width: "25%" }}>
            cancel
          </MDButton>
        </div>
      </MDBox>
    </MDBox>
  );
};
export default StudentEditorModal;
