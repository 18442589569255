import { axiosInstanceAuth } from "config/axios-config";

export const getSetting = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;

  try {
    const response = await axiosAuth.get("/setting");
    if (response.data.success) {
      return {
        success: true,
        message: response.data.message,
        data: response.data.data,
      };
    } else {
      return {
        success: false,
        message: response.data.message || "Failed to fetch settings.",
        data: null,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: error.message || "An error occurred while fetching settings.",
      data: null,
    };
  }
};

export const getSettingLists = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  const response = {
    data: null,
    success: false,
    message: "",
  };
  try {
    const res = await axiosAuth.get("/setting-list");
    if (res.data.success) {
      response.success = res.data.success;
      response.data = res.data.data;
      response.message = res.data.message;
    }
  } catch (error) {
    response.message = error.response?.data?.message;
  }
  return response;
};
