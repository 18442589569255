import axiosInstance from "config/axios-config.js";

export const createExamNotification = async (data) => {
  const response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.post("/exam-notifications", data);
    if (res.data.success) {
      response.success = res.data.success;
      response.message = res.data.message;
      response.data = res.data.data;
    } else {
      response.message = res.data.message;
    }
  } catch (e) {
    response.message = e.response?.data?.message;
  }
  return response;
};

export const updateExamNotification = async (notification_id, data) => {
  console.log(notification_id, data);
  const response = {
    success: false,
    message: "",
    data: null,
  };

  try {
    const res = await axiosInstance.put(`/exam-notifications/${notification_id}`, data);
    if (res.data?.success) {
      response.success = true;
      response.message = res.data.message;
      response.data = res.data.data;
    } else {
      response.message = res.data.message;
    }
  } catch (error) {
    response.message = error.response.data.message;
  }

  return response;
};
export const deleteExamNotification = async (notification_id) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.delete(`/exam-notifications/${notification_id}`);
    if (res.data?.success) {
      response.success = true;
      response.message = res.data.message || "Exam notification deleted successfully.";
      response.data = null;
    } else {
      response.message = res.data.message || "Failed to delete exam notification.";
      response.success = false;
      response.data = null;
    }
  } catch (error) {
    response.message =
      error.response.data.message || "An error occurred while deleting the exam notification.";
    response.success = false;
    response.data = null;
  }
  return response;
};
