import React, { useState, useEffect } from "react";
import { getStudentAttendanceDetail } from "utils/requests/attendance/attendancepost";
import { Avatar, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Calendar = ({ setIsStudentSelected, student_id }) => {
  const [contributionData, setContributionData] = useState({});
  const [currentYear, setCurrentYear] = useState(2024);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [hoveredDate, setHoveredDate] = useState(null);
  const monthsToShow = 6;

  useEffect(() => {
    const fetchAttendance = async () => {
      const res = await getStudentAttendanceDetail({ student_id });
      if (res.success && Array.isArray(res.data.attendances)) {
        const sortedAttendances = res.data.attendances.sort((a, b) => {
          const dateA = new Date(a.updatedAt);
          const dateB = new Date(b.updatedAt);
          return dateA - dateB;
        });

        const transformedData = {};
        sortedAttendances.forEach((attendance) => {
          const date = new Date(attendance.date).toISOString().split("T")[0];
          transformedData[date] = attendance.type;
        });
        setContributionData(transformedData);
      }
    };
    fetchAttendance();
  }, [student_id]);

  const calculateDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "absent":
        return "bg-red-500";
      case "present":
        return "bg-green-500";
      case "delay":
        return "bg-orange-500";
      default:
        return "bg-gray-200";
    }
  };

  const generateDaysGrid = (year, month) => {
    const days = [];
    const daysInMonth = calculateDaysInMonth(year, month);
    const firstDayOfWeek = new Date(year, month - 1, 1).getDay();

    for (let i = 0; i < firstDayOfWeek; i++) {
      days.push(<div key={`empty-${i}`} className="w-10 h-10" />);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const dateKey = `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
      const status = contributionData[dateKey];

      days.push(
        <div
          key={dateKey}
          className={`w-10 h-10 flex justify-center items-center border ${getStatusColor(status)}`}
          onMouseEnter={() => setHoveredDate({ date: dateKey, day })}
          onMouseLeave={() => setHoveredDate(null)}
        >
          {day}
          {hoveredDate && hoveredDate.date === dateKey && (
            <div className="absolute bg-gray-700 text-white p-1 rounded text-xs -top-8 -left-4">
              {`${hoveredDate.day} on ${hoveredDate.date}: ${status || "No record"}`}
            </div>
          )}
        </div>
      );
    }

    return days;
  };

  const handleChangeMonth = (change) => {
    setCurrentMonth((prev) => {
      const newMonth = prev + change;
      if (newMonth < 1) {
        setCurrentYear((year) => year - 1);
        return 12;
      } else if (newMonth > 12) {
        setCurrentYear((year) => year + 1);
        return 1;
      }
      return newMonth;
    });
  };

  const arrowBackHandler = () => {
    setIsStudentSelected(false);
  };

  return (
    <div className="p-4 sm:p-6 rounded-lg">
      <div className="flex flex-col space-y-1 sm:flex-row sm:justify-between sm:items-center mb-4">
        <MDBox display="flex" alignItems="center">
          <Avatar
            style={{
              backgroundColor: "white",
              padding: 8,
              cursor: "pointer",
            }}
          >
            <IconButton>
              <ArrowBack style={{ color: "#1976d2" }} onClick={arrowBackHandler} />
            </IconButton>
          </Avatar>
        </MDBox>
        <h2 className="text-lg sm:text-xl font-semibold text-center sm:text-left">
          Calendar {currentYear}
        </h2>
        <div className="flex justify-center sm:justify-end space-x-2 mt-2 sm:mt-0">
          <button
            className="px-2 sm:px-4 py-1 sm:py-2 rounded"
            onClick={() => handleChangeMonth(-monthsToShow)}
          >
            <ArrowBackIosIcon />
          </button>
          <button
            className="px-2 sm:px-4 py-1 sm:py-2 rounded"
            onClick={() => handleChangeMonth(monthsToShow)}
          >
            <ArrowForwardIosIcon />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {Array.from({ length: monthsToShow }).map((_, index) => {
          const month = ((currentMonth + index - 1) % 12) + 1;
          const year = currentYear + Math.floor((currentMonth + index - 1) / 12);
          return (
            <div key={index} className="p-4 rounded border border-gray-300">
              <h3 className="text-center text-md sm:text-lg font-semibold mb-2">
                {new Date(year, month - 1).toLocaleString("default", { month: "long" })} {year}
              </h3>
              <div className="grid grid-cols-7 gap-2">{generateDaysGrid(year, month)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Calendar.propTypes = {
  setIsStudentSelected: PropTypes.func.isRequired,
  student_id: PropTypes.string.isRequired,
};

export default Calendar;
