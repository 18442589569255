import { Card, CardContent, MenuItem, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { getExamType } from "utils/requests/exam/examrequest";
import { generateQuestion } from "utils/requests/generateQuetion/generateQuestion";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { snackbar } from "components/awesome_snackbar/snackbar";
import { LoadinSpiner } from "components/LoadingSpinner";

const GenerateExamForm = ({ onQuestionsGenerated }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [examType, setExamType] = useState([]);
  const [examList, setExamList] = useState({
    examTopic: "",
    examType: "",
    question: "",
    totalQuestions: "",
    trueFalseQuestions: "",
    shortAnswerQuestions: "",
    blackSpaceQuestions: "",
    choiceQuestions: "",
    gradeLevel: "",
  });

  useEffect(() => {
    const fetchExamTypeData = async () => {
      const res = await getExamType();
      if (res.success) {
        setExamType(res.data);
      }
    };
    fetchExamTypeData();
  }, []);

  const handleInputChange = (field, value) => {
    setExamList((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    try {
      const data = {
        examType: examList.examType,
        examTopic: examList.examTopic,
        question: examList.question,
        totalQuestions: examList.totalQuestions,
        trueFalseQuestions: examList.trueFalseQuestions,
        shortAnswerQuestions: examList.shortAnswerQuestions,
        blackSpaceQuestions: examList.blackSpaceQuestions,
        choiceQuestions: examList.choiceQuestions,
        gradeLevel: examList.gradeLevel,
      };
      setIsGenerating(true);
      const res = await generateQuestion(data);

      if (res.success && res.data) {
        setIsGenerating(false);
        snackbar("success", res.message);
        onQuestionsGenerated(res); // Ensure data is passed
        setExamList({
          examTopic: "",
          examType: "",
          question: "",
          totalQuestions: "",
          trueFalseQuestions: "",
          shortAnswerQuestions: "",
          blackSpaceQuestions: "",
          choiceQuestions: "",
          gradeLevel: "",
        });
      } else {
        setIsGenerating(false);
        console.error("Invalid response from server:", res);
        snackbar("error", "Failed to generate questions.");
      }
    } catch (error) {
      setIsGenerating(false);
      console.error("Error submitting form:", error);
      snackbar("error", "An error occurred while generating questions.");
    }
  };

  return (
    <Card sx={{ height: "auto", width: "100%" }}>
      <CardContent>
        <MDBox
          pt={2}
          px={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <MDTypography variant="h6" fontWeight="medium">
            Generate Exam Sheet
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDBox
            display="flex"
            gap={2}
            px={2}
            mt={2}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <MDInput
              label="Exam Topic"
              value={examList.examTopic}
              onChange={(e) => handleInputChange("examTopic", e.target.value)}
              sx={{ width: { xs: "100%", sm: "45%" } }}
              required
            />
            <TextField
              fullWidth
              select
              label="Exam Type"
              value={examList.examType}
              onChange={(e) => handleInputChange("examType", e.target.value)}
              sx={{ "& .MuiInputBase-root": { height: "44px" }, width: { xs: "100%", sm: "45%" } }}
            >
              {examType.map((type) => (
                <MenuItem key={type.exam_id} value={type.exam_name}>
                  {type.exam_name}
                </MenuItem>
              ))}
            </TextField>
          </MDBox>

          <MDBox
            display="flex"
            gap={2}
            px={2}
            mt={2}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <MDInput
              label="Total Questions"
              value={examList.totalQuestions}
              onChange={(e) => handleInputChange("totalQuestions", e.target.value)}
              sx={{ width: { xs: "100%", sm: "45%" } }}
              type="number"
              required
            />
            <MDInput
              label="True / False Questions"
              value={examList.trueFalseQuestions}
              onChange={(e) => handleInputChange("trueFalseQuestions", e.target.value)}
              sx={{ width: { xs: "100%", sm: "45%" } }}
              type="number"
              required
            />
          </MDBox>

          <MDBox
            display="flex"
            gap={2}
            px={2}
            mt={2}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <MDInput
              label="Short Answer Questions"
              value={examList.shortAnswerQuestions}
              onChange={(e) => handleInputChange("shortAnswerQuestions", e.target.value)}
              sx={{ width: { xs: "100%", sm: "45%" } }}
              type="number"
              required
            />
            <MDInput
              label="Choice Questions"
              value={examList.choiceQuestions}
              onChange={(e) => handleInputChange("choiceQuestions", e.target.value)}
              sx={{ width: { xs: "100%", sm: "45%" } }}
              required
            />
          </MDBox>

          <MDBox display="flex" px={2} mt={2} gap={2}>
            <MDInput
              label="Black Space Questions"
              value={examList.blackSpaceQuestions}
              onChange={(e) => handleInputChange("blackSpaceQuestions", e.target.value)}
              sx={{ width: { xs: "100%", sm: "45%" } }}
              required
            />
            <MDInput
              label="Grade Level"
              value={examList.gradeLevel}
              onChange={(e) => handleInputChange("gradeLevel", e.target.value)}
              sx={{ width: { xs: "100%", sm: "45%" } }}
              required
            />
          </MDBox>

          {/* <MDBox display="flex" px={2} mt={2}>
            <MDInput
              label="Question"
              value={examList.question}
              onChange={(e) => handleInputChange("question", e.target.value)}
              sx={{ width: "91.5%" }}
              multiline
              rows={2}
              required
            />
          </MDBox> */}

          {!isGenerating ? (
            <MDButton
              color="info"
              variant="contained"
              sx={{ mt: 3, width: "20%", ml: "15%" }}
              onClick={handleSubmit}
            >
              Generate Question
            </MDButton>
          ) : (
            <LoadinSpiner />
          )}
        </MDBox>
      </CardContent>
    </Card>
  );
};

GenerateExamForm.propTypes = {
  onQuestionsGenerated: PropTypes.func.isRequired,
};

export default GenerateExamForm;
