import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { LoadinSpiner } from "components/LoadingSpinner";
import { snackbar } from "components/awesome_snackbar/snackbar";
import ConfirmationDialog from "helper/comfirmationOfDelete";
import EditableTable from "helper/editableTable";
import { getExamNotification } from "utils/requests/examNotification/getExamNotification";
import { updateExamNotification } from "utils/requests/examNotification/createExamNotification";
import { deleteExamNotification } from "utils/requests/examNotification/createExamNotification";
import AddExamNotificationForm from "./addExamNotificationForm";
// Function to validate required fields
const validateRequired = (value) => {
  return (typeof value === "string" && value.trim().length > 0) || typeof value === "number";
};

// Function to validate user inputs
const validateExamNotification = (data) => ({
  grade_level: !validateRequired(data.grade_level) ? "Grade level is required" : "",
  subject: !validateRequired(data.subject) ? "Subject is required" : "",
  exam_type: !validateRequired(data.exam_type) ? "Exam Type is required" : "",
  date: !validateRequired(data.date) ? "Date is required" : "",
  start_time: !validateRequired(data.start_time) ? "Start Time is required" : "",
  end_time: !validateRequired(data.end_time) ? "End Time is required" : "",
  remarks: "",
});

export default function ExamNotification() {
  const [addVisibility, setAddVisibility] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [rows, setRows] = useState([]);
  const [is_refresh, setIsRefresh] = useState(false);

  const columnsDefinition = useMemo(
    () => [
      {
        accessorKey: "grade_level",
        header: "Grade",
        size: 100,
        muiEditTextFieldProps: {
          disabled: true,
        },
      },
      {
        accessorKey: "subject",
        header: "Subject",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.subject,
          helperText: validationErrors?.subject,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, subject: undefined })),
        },
      },
      {
        accessorKey: "exam_type",
        header: "Exam Type",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.exam_type,
          helperText: validationErrors?.exam_type,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, exam_type: undefined })),
        },
      },
      {
        accessorKey: "date",
        header: "Date",
        muiEditTextFieldProps: {
          type: "date",
          required: true,
          error: !!validationErrors?.date,
          helperText: validationErrors?.date,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, date: undefined })),
        },
      },
      {
        accessorKey: "start_time",
        header: "Start Time",
        muiEditTextFieldProps: {
          type: "time",
          required: true,
          error: !!validationErrors?.start_time,
          helperText: validationErrors?.start_time,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, start_time: undefined })),
        },
      },
      {
        accessorKey: "end_time",
        header: "End Time",
        muiEditTextFieldProps: {
          type: "time",
          required: true,
          error: !!validationErrors?.end_time,
          helperText: validationErrors?.end_time,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, end_time: undefined })),
        },
      },
      {
        accessorKey: "remarks",
        header: "Remarks",
        muiEditTextFieldProps: {
          multiline: true,
          maxRows: 3,
          error: !!validationErrors?.remarks,
          helperText: validationErrors?.remarks,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, remarks: undefined })),
        },
      },
    ],
    [validationErrors]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getExamNotification();
        setRows(response.data || []);
      } catch (err) {
        setError(err);
      }
    };

    fetchData();
  }, [is_refresh]);

  const onEdit = async (updatedRow) => {
    const { values, row, table } = updatedRow;
    const newValidationErrors = validateExamNotification(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    console.log(values);
    setValidationErrors({});
    const notification_id = row.original?.notification_id;
    console.log(notification_id);

    if (notification_id) {
      console.log(notification_id);
      console.log(values);

      try {
        const response = await updateExamNotification(notification_id, values);

        if (response.success) {
          snackbar("success", response.message);
          setIsRefresh(!is_refresh);
          table.setEditingRow(null);
        } else {
          snackbar("error", response.message);
        }
      } catch {
        snackbar("error", "Failed to update exam notification");
      }
    }
  };

  const handleDelete = async () => {
    const notification_id = deleteRow?.original?.notification_id;
    if (notification_id) {
      try {
        const response = await deleteExamNotification(notification_id);
        if (response.success) {
          snackbar("success", response.message);
          setIsRefresh(!is_refresh);
        } else {
          snackbar("error", response.message);
        }
      } catch {
        snackbar("error", "Failed to delete exam type");
      }
    }
    setDialogOpen(false);
  };

  const onDelete = (row) => {
    setDeleteRow(row);
    setDialogOpen(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                Exam Notification
              </MDTypography>
              <MDBox display="flex" alignItems="center">
                <Avatar style={{ backgroundColor: "white", padding: 8, cursor: "pointer" }}>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() => setAddVisibility(!addVisibility)}
                  >
                    {addVisibility ? (
                      <ArrowBack style={{ color: "#1976d2" }} />
                    ) : (
                      <AddIcon style={{ color: "#1976d2" }} />
                    )}
                  </IconButton>
                </Avatar>
              </MDBox>
            </MDBox>
            {addVisibility ? (
              <AddExamNotificationForm onExamLoad={() => setIsRefresh(!is_refresh)} />
            ) : (
              <MDBox mt={2} ml={2} mr={2}>
                {loading ? (
                  <LoadinSpiner />
                ) : error ? (
                  <MDTypography color="error">
                    Something went wrong. Please try again later.
                  </MDTypography>
                ) : rows.length > 0 ? (
                  <EditableTable
                    columns={columnsDefinition}
                    data={rows}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    validationErrors={validationErrors}
                    setValidationErrors={setValidationErrors}
                  />
                ) : (
                  <p className="text-gray-600 font-normal text-center capitalize text-[13px]">
                    no exam schedule created
                  </p>
                )}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <ConfirmationDialog
        open={dialogOpen}
        title="Delete exam notification "
        message="Are you sure you want to delete this exam notification? This action will affect associated data."
        onClose={() => setDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </DashboardLayout>
  );
}
