import { axiosInstanceAuth } from "config/axios-config";
export const BroadcastMessage = async (braodcastJson) => {
  const response = {
    data: null,
    message: "",
    success: false,
  };
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const res = await axiosAuth.post("/message", braodcastJson);
    if (res.data.success) {
      response.data = res.data.data;
      response.message = res.data.message;
      response.success = res.data.success;
    } else {
      response.message = res.data.message;
    }
  } catch (error) {
    response.message = error?.response?.data?.message;
  }
  return response;
};

export const Resend = async (data) => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  const response = {
    data: null,
    success: false,
    message: "",
  };
  try {
    const res = await axiosAuth.post("/message/resend", data);
    if (res.data.success) {
      response.data = res.data.data;
      response.success = res.data.success;
      response.message = res.data.message;
    } else {
      response.message = res.data.message;
    }
  } catch (error) {
    console.error("Error while sending request", error);
    response.message = error.response.data.message;
  }
  return response;
};
