import axiosInstance from "../../../config/axios-config";

export const updateStaff = async (data) => {
  console.log("data fetched", data);
  const response = {
    success: false,
    message: "",
    data: null,
  };

  // Check if staff_id is present
  if (!data.staff_id) {
    response.message = "Staff ID is required.";
    return response;
  }

  try {
    const res = await axiosInstance.put(`/staff/${data.staff_id}`, {
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
      email: data.email,
      subject_id: data.subject_id,
      educational_level: data.educational_level,
      role: data.role,
      grade_section: data.grade_section,
      grade_level: data.grade_level,
    });
    if (res.data.success) {
      response.success = res.data.success;
      response.message = res.data.message;
      response.data = res.data.data;
    } else {
      response.message = res.data.message || "Failed to update staff profile.";
    }
  } catch (error) {
    // Provide a fallback message if error.response is not defined
    response.message =
      error.response?.data?.message || "An error occurred while updating the staff profile.";
  }

  return response;
};

export const deleteStaff = async (staff_id) => {
  console.log("staff_id", staff_id);
  const response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const res = await axiosInstance.delete(`/staff/${staff_id}`);
    console.log("response", res);
    if (res.data.success) {
      response.success = res.data.success;
      response.message = res.data.message;
      response.data = res.data.data;
    } else {
      response.message = res.data.message || "Failed to delete staff.";
    }
  } catch (e) {
    response.message = e.response?.data?.message || "An error occurred while deleting the staff.";
  }
  return response;
};
