import { useEffect, useRef, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getMessage } from "utils/requests/message/fetchmessage";
import { LoadinSpiner } from "components/LoadingSpinner";
import BroadcastinghistoryCard from "components/broadcasthistorycard/broadcastinhistorycard";
import { historymessagesGetter } from "context/historymessages";
import PropTypes from "prop-types";

function BroadcastHistory({ isrefetch }) {
  const [messages, setMessages] = useState([]); // Initialize as empty array
  const [isFetching, setIsFetching] = useState(false);
  const [is_resend, setIsResend] = useState(false);
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const fetching = async () => {
      setIsFetching(true);
      await getMessage(); // Assuming this fetches all messages
      setMessages(historymessagesGetter()); // Store all messages at once
      setIsFetching(false);
    };
    fetching();
  }, [isrefetch, is_resend]);

  const handleResend = (value) => {
    setIsResend(value);
  };

  return (
    <MDBox ref={containerRef} p={2} height="236px" overflow="auto">
      {isFetching ? (
        <LoadinSpiner />
      ) : messages.length === 0 ? (
        <MDTypography variant="body2" color="gray" align="center">
          No messages to display
        </MDTypography>
      ) : (
        <>
          {messages.map((message) => (
            <MDBox
              pt={2}
              px={2}
              position="relative"
              key={message.message_id}
              onMouseEnter={() => setHoveredMessageId(message.message_id)}
              onMouseLeave={() => setHoveredMessageId(null)}
            >
              <BroadcastinghistoryCard
                message={message.message}
                date={message.createdAt}
                role={message.staff.role}
                message_id={message.message_id}
                status={message.status}
                resend={handleResend}
              />
              {/* Conditionally render the receiver list on hover */}
              {hoveredMessageId === message.message_id && (
                <MDBox
                  sx={{
                    position: "absolute",
                    background: "white",
                    boxShadow: 2,
                    borderRadius: 1,
                    padding: 1,
                    zIndex: 10,
                    maxHeight: "150px",
                    overflowY: "auto",
                    top: 20,
                    left: "0%",
                  }}
                >
                  <MDTypography variant="subtitle2" align="center">
                    Receiver List
                  </MDTypography>
                  {message.receiver_list.map((receiver, index) => (
                    <MDTypography
                      key={receiver.student_id}
                      variant="body2"
                      sx={{
                        padding: 0.5,
                        cursor: "pointer",
                        borderBottom:
                          index < message.receiver_list.length - 1 ? "1px solid #F5F5F5" : "none",
                      }}
                    >
                      {receiver.student_name}
                    </MDTypography>
                  ))}
                </MDBox>
              )}
            </MDBox>
          ))}
        </>
      )}
    </MDBox>
  );
}

BroadcastHistory.propTypes = {
  isrefetch: PropTypes.bool.isRequired,
};

export default BroadcastHistory;
