import axiosInstance from "config/axios-config.js";

export const postattendance = async (data) => {
  let response = {
    message: "",
    success: "",
  };
  try {
    const result = await axiosInstance.post("/attendance", {
      attendances: data,
    });
    response.success = result.data.success;
    response.message = result.data.message;
  } catch (error) {
    response.success = false;
    response.message = error.response.data.message;
    console.error("Error submitting data:", error);
  }
  return response;
};

export const getStudentAttendanceDetail = async ({ student_id }) => {
  let response = {
    message: "",
    success: "",
    data: null,
  };
  try {
    const result = await axiosInstance.get(`/attendance/${student_id}`);
    response.success = result.data.success;
    response.message = result.data.message;
    response.data = result.data.data;
  } catch (error) {
    response.success = false;
    response.message = error.response.data.message;
    console.error("Error fetching data:", error);
  }
  return response;
};

export const getAttendanceByClass = async () => {
  let response = {
    message: "",
    success: false,
    data: null,
  };
  try {
    const result = await axiosInstance.get(`/attendance/class`);
    response.success = result.data.success;
    response.message = result.data.message;
    response.data = result.data.data;
  } catch (error) {
    response.message = error.response.data.message;
  }
  return response;
};
