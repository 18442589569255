import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, Typography } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { getSchedules } from "utils/requests/schedule/getSchedule";
import EditableTable from "helper/editableTable";
import ConfirmationDialog from "helper/comfirmationOfDelete";
import { updateProgram } from "utils/requests/schedule/createSchedule";
import { deleteProgram } from "utils/requests/schedule/createSchedule";
import { snackbar } from "components/awesome_snackbar/snackbar";
import MDBox from "components/MDBox";
import { LoadinSpiner } from "components/LoadingSpinner";

const validateRequired = (value) => {
  return (typeof value === "string" && value.trim().length > 0) || typeof value === "number";
};

// Function to validate user inputs
const validateProgram = (data) => {
  return {
    Monday: !validateRequired(data.Monday) ? "Monday is required" : "",
    Tuesday: !validateRequired(data.Tuesday) ? "Tuesday is required" : "",
    Wednesday: !validateRequired(data.Wednesday) ? "Wednesday is required" : "",
    Thursday: !validateRequired(data.Thursday) ? "Thursday is required" : "",
    Friday: !validateRequired(data.Friday) ? "Friday is required" : "",
  };
};

const ScheduleTable = () => {
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [sections, setSections] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [schedule, setSchedule] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [is_refresh, setIsRefresh] = useState(false);
  const [row, setRow] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Fetch schedule data when the component mounts
  useEffect(() => {
    const fetchSchedules = async () => {
      setIsLoading(true);
      const response = await getSchedules();
      // console.log("response from server: ", response);
      setIsLoading(false);
      if (response) {
        setSchedule(response.data.programs);
      } else {
        console.error("Failed to fetch schedules.");
      }
    };
    fetchSchedules();
  }, [is_refresh]);

  // Extract grade levels from the schedule
  const gradeLevels = Object.keys(schedule?.[Object.keys(schedule)[0]]?.schedules || {});

  // Handle grade change and populate sections
  const handleGradeChange = (event) => {
    const selectedGradeNumber = event.target.value;
    setSelectedGrade(selectedGradeNumber);
    setSelectedSection(""); // Reset selected section when grade changes

    // Get the nested schedule data for the selected grade
    const gradeSchedules =
      schedule?.[Object.keys(schedule)[0]]?.schedules?.[selectedGradeNumber] || [];

    if (gradeSchedules.length > 0) {
      // Assuming the structure is: [ { grade X: { sections: [...] } } ]
      const gradeData = gradeSchedules[0]; // Get the first (and only) object in the array
      const sectionsForGrade = Object.keys(gradeData[Object.keys(gradeData)[0]]); // Extract sections
      setSections(sectionsForGrade);
    } else {
      setSections([]);
    }
  };

  // Update table data when both grade and section are selected
  useEffect(() => {
    if (selectedGrade && selectedSection) {
      const selectedGradeSchedules =
        schedule?.[Object.keys(schedule)[0]]?.schedules?.[selectedGrade]?.[0]?.[
          `grade ${selectedGrade}`
        ]?.[selectedSection];

      if (selectedGradeSchedules) {
        const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
        const maxBlocks = Math.max(
          ...daysOfWeek.map((day) =>
            selectedGradeSchedules[day] ? selectedGradeSchedules[day].length : 0
          )
        );

        // Build table data for each block and day
        const data = [];
        for (let blockIndex = 0; blockIndex < maxBlocks; blockIndex++) {
          const row = { Block: blockIndex + 1 };
          daysOfWeek.forEach((day) => {
            row[day] = selectedGradeSchedules[day]?.[blockIndex] || "Free"; // Show "Free" if no class is scheduled
          });
          data.push(row);
        }
        setTableData(data);
      } else {
        setTableData([]);
      }
    } else {
      setTableData([]); // Clear table if no grade or section selected
    }
  }, [selectedGrade, selectedSection, schedule, is_refresh]);

  // Define table columns
  const columns = [
    {
      accessorKey: "Block",
      header: "Block",
      size: 150,
    },
    {
      accessorKey: "Monday",
      header: "Monday",
      size: 150,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors?.Monday,
        helperText: validationErrors?.Monday,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, Monday: undefined })),
      },
    },
    {
      accessorKey: "Tuesday",
      header: "Tuesday",
      size: 150,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors?.Tuesday,
        helperText: validationErrors?.Tuesday,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, Tuesday: undefined })),
      },
    },
    {
      accessorKey: "Wednesday",
      header: "Wednesday",
      size: 150,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors?.Wednesday,
        helperText: validationErrors?.Wednesday,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, Wednesday: undefined })),
      },
    },
    {
      accessorKey: "Thursday",
      header: "Thursday",
      size: 150,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors?.Thursday,
        helperText: validationErrors?.Thursday,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, Thursday: undefined })),
      },
    },
    {
      accessorKey: "Friday",
      header: "Friday",
      size: 150,
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors?.Friday,
        helperText: validationErrors?.Friday,
        onFocus: () => setValidationErrors((prev) => ({ ...prev, Friday: undefined })),
      },
    },
  ];

  const onEdit = async (updatedRow) => {
    const { values, row, table } = updatedRow;
    const newValidationErrors = validateProgram(values);

    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});

    // Check that `values` has at least one property and that all values are not empty
    const hasNonEmptyValues = Object.values(values).every((val) => Boolean(val));

    if (hasNonEmptyValues) {
      try {
        const data = {
          grade: selectedGrade,
          section: selectedSection,
          schedule: Object.keys(values)
            .filter((day) => day !== "Block")
            .map((day) => ({
              day,
              subject: values[day],
              index: row.index,
            })),
        };

        const response = await updateProgram(data);
        if (response.success) {
          snackbar("success", response.message);
          setIsRefresh(!is_refresh);
          table.setEditingRow(null);
        } else {
          snackbar("error", response.message);
        }
      } catch {
        snackbar("error", "Failed to update program");
      }
    } else {
      snackbar("error", "All schedule values must be non-empty");
    }
  };

  const handleDelete = async () => {
    if (row) {
      console.log("row ", row);
      try {
        const data = {
          grade: selectedGrade,
          section: selectedSection,
          day: Object.keys(row.original)
            .filter((day) => day !== "Block")
            .map((day) => ({
              day,
              index: row.index,
            })),
        };
        const response = await deleteProgram(data);
        if (response.success) {
          snackbar("success", response.message);
          setIsRefresh(!is_refresh);
        } else {
          snackbar("error", response.message);
        }
      } catch (error) {
        snackbar("error", "Failed to delete program row ");
      }
    }
    setDialogOpen(false);
  };

  const onDelete = (row) => {
    setRow(row);
    setDialogOpen(true);
  };

  return (
    <MDBox>
      {isLoading ? (
        <LoadinSpiner />
      ) : (
        <>
          {schedule && schedule.length > 0 ? (
            <Paper>
              {selectedGrade && (
                <Typography variant="h6" align="center" style={{ padding: "10px", color: "gray" }}>
                  Weekly Schedule for Grade {selectedGrade || "select grade"},{" "}
                  {selectedSection || "select section"}
                </Typography>
              )}

              {/* Grade Level Dropdown */}
              <select
                onChange={handleGradeChange}
                value={selectedGrade}
                style={{
                  marginBottom: "10px",
                  width: "12%",
                  padding: "5px",
                  textAlign: "center",
                  backgroundColor: "white",
                }}
              >
                <option
                  value=""
                  style={{
                    backgroundColor: "white",
                    textAlign: "center",
                    fontSize: "12px",
                    color: "white",
                  }}
                  // disabled
                >
                  Grade Level
                </option>
                {gradeLevels.map((grade) => (
                  <option
                    key={grade}
                    value={grade}
                    style={{
                      backgroundColor: "white",
                      textAlign: "center",
                      border: "none",
                      color: "gray",
                      fontSize: "12px",
                    }}
                  >
                    {grade}
                  </option>
                ))}
              </select>

              {/* Section Dropdown */}
              {selectedGrade && (
                <select
                  onChange={(e) => setSelectedSection(e.target.value)}
                  value={selectedSection}
                  style={{ marginBottom: "10px", width: "15%", padding: "5px" }}
                >
                  <option value="" disabled className="text-[12px]">
                    Section
                  </option>
                  {sections.length > 0 ? (
                    sections.map((section) => (
                      <option key={section} value={section} className="text-[12px]">
                        {section}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled className="text-gray-500">
                      No sections available
                    </option>
                  )}
                </select>
              )}

              {/* Message if no schedule is available */}
              {tableData.length === 0 && selectedGrade && selectedSection && (
                <Typography variant="body2" color="error" align="center" sx={{ color: "gray" }}>
                  No schedule available for this section.
                </Typography>
              )}

              {/* Render Schedule Table */}
              <EditableTable
                columns={columns}
                data={tableData}
                onEdit={onEdit}
                onDelete={onDelete}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
              />

              <ConfirmationDialog
                open={dialogOpen}
                title="Delete class program"
                message="Are you sure you want to delete this class program? This action will affect associated data."
                onClose={() => setDialogOpen(false)}
                onConfirm={handleDelete}
              />
            </Paper>
          ) : (
            <Typography variant="body2" color="error" align="center" sx={{ color: "gray" }}>
              No schedule available for this school.
            </Typography>
          )}
        </>
      )}
    </MDBox>
  );
};

// Define prop types for schedule data validation
ScheduleTable.propTypes = {
  schedule: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        school_id: PropTypes.string,
        schedules: PropTypes.objectOf(PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))),
      })
    )
  ).isRequired,
};

export default ScheduleTable;
