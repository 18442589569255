import { axiosInstanceAuth } from "config/axios-config";
import axiosInstance from "config/axios-config.js";
export const getStudents = async () => {
  const response = {
    success: false,
    data: null,
    message: "",
    school_plan: null,
  };
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const res = await axiosAuth.get("/student/school");
    console.log(JSON.stringify(res, null, 2));
    if (res.data.success) {
      response.status = res.data.success;
      response.data = res.data.data;
      response.message = res.data.message;
      response.school_plan = res.data.school_plan_id;
    }
  } catch (error) {
    response.message = error.response.data.message;
  }
  return response;
};
export const getAllStudentForAdmin = async () => {
  const response = {
    success: false,
    data: null,
    message: "",
    school_plan: null,
  };
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const res = await axiosAuth.get("/student/admin");
    console.log(JSON.stringify(res, null, 2));
    if (res.data.success) {
      response.status = res.data.success;
      response.data = res.data.data;
      response.message = res.data.message;
      response.school_plan = res.data.school_plan_id;
    }
  } catch (error) {
    response.message = error.response.data.message;
  }
  return response;
};

export const getStudentsByClass = async () => {
  const response = {
    success: false,
    data: null,
    message: "",
    school_plan: null,
  };
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  try {
    const res = await axiosAuth.get("/student/class");
    // // console.log("res: " + JSON.stringify(res, null, 2));
    if (res.data.success) {
      response.success = res.data.success;
      response.data = res.data.data;
      response.message = res.data.message;
      response.school_plan = res.data.school_plan_id;
    }
  } catch (error) {
    response.message = error.response.data.message;
  }
  return response;
};

export const createStudents = async (obj) => {
  const response = {
    success: false,
    data: null,
    message: "",
  };

  try {
    const res = await axiosInstance.post("/parent", obj);
    console.log("res: " + JSON.stringify(res, null, 2));
    if (res.data.success) {
      response.success = true;
      response.message = res.data.message;
      response.data = res.data.data;
    } else {
      response.success = res.data.success;
      response.message = res.data.message;
      response.data = null;
    }
  } catch (error) {
    console.log("error in get student data", error);
    response.success = false;
    response.message = error.response?.data?.message;
    response.data = null;
  }

  return response;
};

export const updateStudents = async (obj) => {
  // console.log("object from request ", obj);
  let response = await axiosInstance.put(`/student/${obj.student_id}`, obj).catch((error) => {
    // console.log("error", error);
    return { success: false, error: "Can't update students" };
  });
  return response;
};

export const deleteStudent = async (student_id) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };
  try {
    response = await axiosInstance.delete(`/student/${student_id}`);
    console.log("response from delete", response);
    if (response.data.success) {
      response = {
        success: true,
        message: response.data.message,
        data: response.data.data,
      };
    } else {
      response.message = response.data.message || "Failed to delete staff.";
    }
  } catch (e) {
    response.message = e.response?.data?.message || "An error occurred while deleting the staff.";
  }
  return response;
};
